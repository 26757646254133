import React from 'react'
import Navbar from '../inc/Navbar'
import Asrsenitization from "../images/ASR - sensitization.jpg";
import august15 from "../images/August 15 independnce day celebrations.jpg"
import Nabard from "../images/rogram under NABARD.jpg";
import Biotechworkshop from "../images/Bio tech workshop.jpg"
import { Link } from "react-router-dom";
import BiotechCommitte from "../images/biotec-committe.jpg"
import Cocoonkallimpong from "../images/cocoon selection at kallimpong.jpg";
import Sargachi from "../images/sargachi KVK.jpg";
import exhibitionatKolkata from "../images/AT KOLKATA.jpg";
import Gallerycs from './Gallerycs';

function Gallery() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
            <div className="sericultural">

                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#"> Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <Gallerycs/>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='AsrSensitization'>
                                        <div className="card">
                                            <img src={Asrsenitization} className="card-img-top" alt="ASR - sensitization" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">ASR - sensitization</h5>
                                                <p className="card-text">ASR</p>

                                                <div className="btn-grad15" type="submit"><small>View Photo</small> </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='August15'>
                                        <div className="card">
                                            <img src={august15} className="card-img-top" alt="Hansh" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">August 15</h5>
                                                <p className="card-text">independnce day celebrations</p>

                                                <a href="August15" className="btn-grad15"><small>View Photo</small> </a>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='AwarenessProgram'>
                                        <div className="card">
                                            <img src={Nabard} className="card-img-top" alt="AwarenessProgram" />
                                            <div className="card-body">
                                                <h5 className="card-title mb-1">Awareness Program </h5>
                                                <p className="card-text">Under NABARD</p>

                                                <div className="btn-grad15"><small>View Photo</small> </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='BioTech-workshop'>
                                        <div className="card">
                                            <img src={Biotechworkshop} className="card-img-top" alt="Bio Tech Workshop" />
                                            <div className="card-body">
                                                <h5 className="card-title mb-1" >Bio Tech Workshop</h5>
                                                <p className="card-text">Workshop</p>

                                                <div className="btn-grad15"><small>View Photo </small> </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Biotechcommitte'>
                                        <div className="card">
                                            <img src={BiotechCommitte} className="card-img-top" alt="BiotechCommitte" />
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Biotech Committe</h5>
                                                <p className="card-text">csrti</p>

                                                <div className="btn-grad15" type="submit"><small>View Photo</small> </div>


                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Cocoon'>
                                        <div className="card">
                                            <img src={Cocoonkallimpong} className="card-img-top" alt="Cocoonkallimpong"/>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Cocoon Selection</h5>
                                                <p className="card-text"> at kallimpong</p>

                                                <div className="btn-grad15"><small>View Photo</small> </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='Exhibition-KVK-sargachi'>
                                        <div className="card">
                                            <img src={Sargachi} className="card-img-top" alt="Sargachi kvk"/>
                                            <div className="card-body">
                                                <h5 className="card-title mb-1">Exhibition -  </h5>
                                                <p className="card-text">KVK sargachi</p>

                                                <div className="btn-grad15"><small>View Photo</small> </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='Exhibition-At-Kolkata'>
                                        <div className="card">
                                            <img src={exhibitionatKolkata} className="card-img-top" alt="exhibitionatKolkata"/>
                                            <div className="card-body">
                                                <h5 className="card-title mb-1" >Exhibition at Kolkata</h5>
                                                <p className="card-text">Kolkata</p>

                                                <div className="btn-grad15"><small>View Photo </small> </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

          
            <br/>

            <br/>
        </div>
    )
}

export default Gallery
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'

import Table from 'react-bootstrap/Table';
import Navbar from '../inc/Navbar';







function Administrativenew() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(98);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "Administration New");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-0">

                <div className="container">
                    <center>
                        <h4>ADVERTISEMENT</h4>
                    </center>
                </div>
            </div>

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-12" >
                                    <div className='card'>

                                        <Table striped>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th><h5 style={{ color: 'Darkblue' }}>Name & designation</h5></th>
                                                    <th>Email - ID</th>
                                                    <th>Mobile No.</th>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{centralsericultural.sl}</td>
                                                    <td>{centralsericultural.name}</td>
                                                    <td>{centralsericultural.email}</td>
                                                    <th>{centralsericultural.mobile}</th>

                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl2}</td>
                                                    <td>{centralsericultural.name2}</td>
                                                    <td>{centralsericultural.email2}</td>
                                                    <th>{centralsericultural.mobile2}</th>

                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl3}</td>
                                                    <td>{centralsericultural.name3}</td>
                                                    <td>{centralsericultural.email4}</td>
                                                    <th>{centralsericultural.mobile5}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl4}</td>
                                                    <td>{centralsericultural.name4}</td>
                                                    <td>{centralsericultural.email4}</td>
                                                    <th>{centralsericultural.mobile4}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl5}</td>
                                                    <td>{centralsericultural.name5}</td>
                                                    <td>{centralsericultural.email5}</td>
                                                    <th>{centralsericultural.mobile5}</th>

                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl6}</td>
                                                    <td>{centralsericultural.name6}</td>
                                                    <td>{centralsericultural.email6}</td>
                                                    <th>{centralsericultural.mobile6}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl7}</td>
                                                    <td>{centralsericultural.name7}</td>
                                                    <td>{centralsericultural.email7}</td>
                                                    <th>{centralsericultural.mobile7}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl8}</td>
                                                    <td>{centralsericultural.name8}</td>
                                                    <td>{centralsericultural.email8}</td>
                                                    <th>{centralsericultural.mobile8}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl9}</td>
                                                    <td>{centralsericultural.name9}</td>
                                                    <td>{centralsericultural.email9}</td>
                                                    <th>{centralsericultural.mobile9}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl10}</td>
                                                    <td>{centralsericultural.name10}</td>
                                                    <td>{centralsericultural.email10}</td>
                                                    <th>{centralsericultural.mobile10}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl11}</td>
                                                    <td>{centralsericultural.name11}</td>
                                                    <td>{centralsericultural.email11}</td>
                                                    <th>{centralsericultural.mobile11}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl12}</td>
                                                    <td>{centralsericultural.name12}</td>
                                                    <td>{centralsericultural.email12}</td>
                                                    <th>{centralsericultural.mobile12}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl13}</td>
                                                    <td>{centralsericultural.name13}</td>
                                                    <td>{centralsericultural.email13}</td>
                                                    <th>{centralsericultural.mobile13}</th>
                                                </tr>
                                                <tr>
                                                    <td>{centralsericultural.sl14}</td>
                                                    <td>{centralsericultural.name14}</td>
                                                    <td>{centralsericultural.email14}</td>
                                                    <th>{centralsericultural.mobile14}</th>
                                                </tr>

                                                <tr>
                                                    <td>{centralsericultural.sl15}</td>
                                                    <td>{centralsericultural.name15}</td>
                                                    <td>{centralsericultural.email15}</td>
                                                    <th>{centralsericultural.mobile15}</th>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>
            <br />
        </>

    );
}

export default Administrativenew;




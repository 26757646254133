import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Aboutus from './components/pages/Aboutus';
import Contacts from './components/pages/Contacts';
import Advertise from './components/pages/Advertise';
import Faculty from './components/pages/Faculty';
import YalavarthiNagaraju from './components/pages/YalavarthiNagaraju';
import Gallery from './components/pages/Gallery';
import AsrSensitization from './components/pages/AsrSensitization';
import August15 from './components/pages/August15';
import AwarenessProgram from './components/pages/AwarenessProgram';
import BioTech from './components/pages/BioTech ';
import Pgds from './components/pages/Pgds';
import Rti from './components/pages/Rti';
import SeriTourism from './components/pages/SeriTourism';
import Forewarning from './components/pages/Forewarning';
import Administration from './components/pages/Administration';
import Biotechcommitte from './components/pages/Biotechcommitte';
import Cocoon from './components/pages/Cocoon';
import Sargachi from './components/pages/Sargachi';
import Kolkata from './components/pages/Kolkata';
import DrOshini from './components/pages/DrOshin ';
import Flora from './components/pages/Flora';
import Awareness from './components/pages/Awareness';
import Directorbenefit from './components/pages/Directorbenefit';
import Adver2 from './components/pages/Adver2';
import Pradeep from './components/pages/Pradeep';
import Rfd from './components/pages/Rfd';
import RelatedLink from './components/pages/RelatedLink';
import Javid from './components/pages/Javid';
import Ravisaini from './components/pages/Ravisaini';
import Patented from './components/pages/Patented';
import Tenders from './components/pages/Tenders';
import Success from './components/pages/Success';
import Circulars from './components/pages/Circulars';
import Harshitha from './components/pages/Harshitha';
import Tendernew from './components/pages/Tendernew';
import NewsViws from './components/pages/NewsViws';
import Rkb from './components/pages/Rkb';
import Poster from './components/pages/Poster';
import Brochure from './components/pages/Brochure';
import Rti1 from './components/pages/Rti1';
import Naik from './components/pages/Naik';
import Directors from './components/pages/Directors';
import AdminstrationFaculty from './components/pages/AdminstrationFaculty';
import Devi from './components/pages/Devi';
import Rti3 from './components/pages/Rti3';
import Rti4 from './components/pages/Rti4';
import Rti5 from './components/pages/Rti5';
import Rti6 from './components/pages/Rti6';
import Rti7 from './components/pages/Rti7';
import Rti13 from './components/pages/Rti13';
import Rti14 from './components/pages/Rti14';
import Rti15 from './components/pages/Rti15';
import Rti16 from './components/pages/Rti16';
import Rti17 from './components/pages/Rti17';
import Feedback from './components/pages/Feedback';
import Minutes from './components/pages/Minutes';
import Reeling from './components/pages/Reeling';
import Extension from './components/pages/Extension';
import PubMinutes from './components/pages/PubMinutes';
import Arunk from './components/pages/Arunk';

import Gallerycs from './components/pages/Gallerycs';
import DocWorkshop from './components/pages/DocWorkshop';
import SeemExten from './components/pages/SeemExten';
import Administrativenew from './components/pages/Adminstrativenew';

function App() {
  return (
    <Router>
   
    <div>
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/about-us" element={<Aboutus/>}/>
      <Route axact path="/Contacts" element={<Contacts/>}/>
      <Route axact path="/advertise" element={<Advertise/>}/>
      <Route axact path="/scientist-profile" element={<Faculty/>}/>

      <Route axact path="/gallery" element={<Gallery/>}/>
      <Route axact path="/AsrSensitization" element={<AsrSensitization/>}/>
      <Route axact path="/August15" element={<August15/>}/>
      <Route axact path="/AwarenessProgram" element={<AwarenessProgram/>}/>
      <Route axact path="/BioTech-workshop" element={<BioTech/>}/>
      <Route axact path="/BioTech-workshop" element={<BioTech/>}/>
      <Route axact path="/pgds" element={<Pgds/>}/>
      <Route axact path="/rti" element={<Rti/>}/>
      <Route axact path="/seri-tourism" element={<SeriTourism/>}/>
      <Route axact path="/Forewarning" element={<Forewarning/>}/>
      <Route axact path="/Administration" element={<Administration/>}/>
      <Route axact path="/Biotechcommitte" element={<Biotechcommitte/>}/>
      <Route axact path="/Cocoon" element={<Cocoon/>}/>
      <Route axact path="/Exhibition-KVK-sargachi" element={<Sargachi/>}/>
      <Route axact path="/Exhibition-At-Kolkata" element={<Kolkata/>}/>

      <Route axact path="/YalavarthiNagaraju" element={<YalavarthiNagaraju/>}/>
      <Route axact path="/DrOshini" element={<DrOshini/>}/>
      <Route axact path="/Pradeepsd" element={<Pradeep/>}/>

      <Route axact path="/flora-founa" element={<Flora/>}/>
      <Route axact path="/Awareness" element={<Awareness/>}/>
      <Route axact path="/Directorbenefit" element={<Directorbenefit/>}/>
      <Route axact path="/Advertisement-home" element={<Adver2/>}/>
      <Route axact path="/rfd" element={<Rfd/>}/>
      <Route axact path="/related-link" element={<RelatedLink/>}/>
      <Route axact path="/Dr.Javid-Ur-Rahaman" element={<Javid/>}/>
      <Route axact path="/mr.Ravisaini" element={<Ravisaini/>}/>
      <Route axact path="/patented" element={<Patented/>}/>
      <Route axact path="/tenders" element={<Tenders/>}/>
      <Route axact path="/Success-story" element={<Success/>}/>
      <Route axact path="/Circulars" element={<Circulars/>}/>
      <Route axact path="/ms-Harshitha-BS" element={<Harshitha/>}/>
      <Route axact path="/Tendernew" element={<Tendernew/>}/>
      <Route axact path="/NewsViews" element={<NewsViws/>}/>
      <Route axact path="/Rkb" element={<Rkb/>}/>
      <Route axact path="/poster" element={<Poster/>}/>
      <Route axact path="/brochure" element={<Brochure/>}/>
      <Route axact path="/Rti-1" element={<Rti1/>}/>
      <Route axact path="/Dr-Parameswaranaik" element={<Naik/>}/>
      <Route axact path="/Dr._Thangjam_Ranjita_Devi" element={<Devi/>}/>
      <Route axact path="/director_speech" element={<Directors/>}/>
      <Route axact path="/faculty_administration" element={<AdminstrationFaculty/>}/>
      <Route axact path="/Rti3" element={<Rti3/>}/>
      <Route axact path="/Rti4" element={<Rti4/>}/>
      <Route axact path="/Rti5" element={<Rti5/>}/>
      <Route axact path="/Rti6" element={<Rti6/>}/>
      <Route axact path="/Rti7" element={<Rti7/>}/>
      <Route axact path="/Rti13" element={<Rti13/>}/>
      <Route axact path="/Rti14" element={<Rti14/>}/>
      <Route axact path="/Rti15" element={<Rti15/>}/>
      <Route axact path="/Rti16" element={<Rti16/>}/>
      <Route axact path="/Rti17" element={<Rti17/>}/>
      <Route axact path="/Feedback" element={<Feedback/>}/>
      <Route axact path="/Minutes" element={<Minutes/>}/>
      <Route axact path="/reeling" element={<Reeling/>}/>
      <Route axact path="/extension" element={<Extension/>}/>
      <Route axact path="/Publication_Minutes" element={<PubMinutes/>}/>
      <Route axact path="/Arun_kumar" element={<Arunk/>}/>
      <Route axact path="/Seem_extention" element={<SeemExten/>}/>
      <Route axact path="/administration_faculty" element={<Administrativenew/>}/>

      <Route axact path="/gallery_csrti" element={<Gallerycs/>}/>
      <Route path="/MorePhoto/:docId" element={<DocWorkshop/>}/>
      
         
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;


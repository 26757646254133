import React from 'react'
import Navbar from '../inc/Navbar'
import Table from 'react-bootstrap/Table';
import {Link} from "react-router-dom";
import RejaulSk from './RejaulSk';
import Clal from './Clal';
import Mazeda from './Mazeda';
import Sherpa from './Sherpa';
import SamsulHq from './SamsulHq';
import jaydeb from "../images/Sucusses story 1.pdf";
import alpha from "../images/Sucusses story 2.pdf";


function Success() {
    return (
        <div>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br/>
          
            <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/Contacts" >Contact Us</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Success Story</li>
            </ol>
        </nav>
    </div>
</div>
</div>
            <div className="album py-3">
                <div className="container">
                    <Table striped bordered hover variant="light">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Details of the Farmers/Rereares/Stakeholders	</th>
                                <th>Document</th>
                               
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Rejaul Sk., Mallickpur Village, Murshidabad	</td>
                                <td><RejaulSk/></td>
                              
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>	C. Lalhmachhuana, Aizwal, Mizoram</td>
                                <td><Clal/></td>
                               
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Smt. Mazeda Khatun, Sipahijala, Tripura</td>
                                <td><Mazeda/></td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Smt. Ongmu Sherpa, Bhalukhop, Kalimpong	</td>
                                <td><Sherpa/></td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Samsul Hk, Nabagram, Murshidabad	</td>
                                <td><SamsulHq/></td>
                            </tr>

                            <tr>
                                <td>6</td>
                                <td>Alfajaddin Sekh, Bhadrapur
                                Birbhum	</td>
                                <td><a style={{color:"black"}} href={alpha}>View/Download</a></td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Shri. Jaydeb Mondal, Harekrishnapur, Nadia	</td>
                                <td><a  style={{color:"black"}} href={jaydeb}>View/Download</a></td>
                            </tr>

                            
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Success
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import Table from 'react-bootstrap/Table';




function Scientist() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(21);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [allpackage, setAllpackage] = useState([])
    const navigate = useNavigate()

    const allpackageCollectionRef = collection(db, "Scientist Profile");
    useEffect(() => {

        const getAllpackage = async () => {
            const data = await getDocs(allpackageCollectionRef);
            setAllpackage(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getAllpackage()
    }, [])
    return (

        <>



            <div className="album py-1">

                <div className="container">

                    <div className="row">

                        <div className="col-md-8">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search Scientist name.." name="search"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="album py-3">

                <div className="container">

                    <div className="row">
                        {allpackage.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((sericultureberhampore) => {
                            return (
                                <div className="card mb-4" >
                                    <div className="row g-0">
                                        {/* <div className="col-md-5 mb-3 mt-2">

                                            <img src={sericultureberhampore.img} height="150px" width="150px" className="img-fluid rounded-start me-0 mb-0" alt={sericultureberhampore.name} />

                                        </div> */}

                                        <div className="col-md-10">
                                            <div className="card-body"> 
                                               
                                            
                                         


                                                <Table striped bordered hover variant="danger">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Name</th>
                                                            <th>Designation</th>
                                                            <th>Email address/Contact Number</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                            <img src={sericultureberhampore.img} height="150px" width="150px" className="img-fluid rounded-start me-0 mb-2" alt={sericultureberhampore.name} />
                                        <br/>
                                                                 <b>{sericultureberhampore.name}</b></td>
                                                            <td>{sericultureberhampore.designation}</td>
                                                            <td>{sericultureberhampore.email} <br/>
                                                            <br/>
                                                            {sericultureberhampore.mobile}
                                                            </td>
                                                           
                                                        </tr>
                                                    
                                                   
                                                       
                                                    </tbody>
                                                </Table>
                                                {/* <Table striped bordered hover size="sm">

                                                <tbody>
                                                    <tr >
                                                        <td > <center className='mb-2 mt-2 smalltext'>ADULT (per Head) (2Heads in a DBR)</center></td>
                                                        <td><center className='mb-2 mt-2 smalltext'>ADULT (per Head) (Heads in a DBR)</center></td>
                                                        <td><center  className='mb-2 mt-2 smalltext' >Child (5yrs to 11 yrs)</center></td>
                                                        <td><center className='mb-2 mt-2 smalltext'>Child (5yrs to 11 yrs)</center></td>



                                                    </tr>

                                                    <tr >
                                                        <td > <center style={{ color: "darkBlue" }} className='mb-2 mt-2'>{sericultureberhampore.rs}</center></td>
                                                        <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>{sericultureberhampore.rs2}</center></td>
                                                        <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>{sericultureberhampore.rs3}</center></td>
                                                        <td><center style={{ color: "darkBlue" }} className='mb-2 mt-2'>{sericultureberhampore.rs4}</center></td>



                                                    </tr>



                                                </tbody>
                                            </Table> */}

                                                {/* <h5 className="card-text me-0"><small className="text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-calendar-check-fill" viewBox="0 0 16 16">
                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708" />
                                            </svg> {sericultureberhampore.day} Days</small></h5> */}
                                                


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>



                </div>
            </div>

        </>

    );
}

export default Scientist;



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";

import { db } from '../../firebase'



import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'



function News() {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };


    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(15);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [news, setNews] = useState([])
    const navigate = useNavigate()

    const newsCollectionRef = collection(db, "News");
    useEffect(() => {

        const getNews = async () => {
            const data = await getDocs(newsCollectionRef);
            setNews(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getNews()
    }, [])
    return (

        <>

            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        {news.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((centralsericultural) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-4 border-White box shadow">
                                        <div className="image-container">
                                            <Zoom>
                                            <img className="card-img-top image-container" height="200px" width="400px" src={centralsericultural.img} alt="news" />
                                            </Zoom>
                                        </div>

                                        <div className="card-body">
                                            <p className="card-text mb-2"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" style={{ color: "grey" }} className="bi bi-calendar-week-fill" viewBox="0 0 16 16">
                                                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M9.5 7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5m3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5M2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5" />
                                            </svg> <small>{centralsericultural.date}</small></p>


                                            <h6 style={{ color: "green" }}>{centralsericultural.title}</h6>



                                            <div className="d-flex justify-content-between align-items-center">



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>

        </>

    );
}

export default News;




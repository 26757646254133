import React, { useState, useEffect } from 'react';

import Modal from 'react-modal';
import Navbar from '../inc/Navbar'
import Directori from './Directori';
import Soilhealth from './Soilhealth';
import Publication from './Publication';
import ReactPlayer from "react-player";
import Notice from './Notice';

import Womenhelp from './Womenhelp';

import ScrollToTop from "react-scroll-to-top";
import Julamam from "../images/Jula mam.png"
import "./Home.css";
// import Techmulberry from './Techmulberry';
import News from './News';
import Program from './Program';
import Slider2 from '../inc/Slider2';
import Linkimp from './Linkimp';
import Hostplantimpro from './Hostplantimpro';
import MullberryProtect from './MullberryProtect';
import Admissionpgds from './Admissionpgds';
import Postponement from './Postponement';
import Pgdsmulberry from './Pgdsmulberry';
import EventHeld from './EventHeld';
import Map from './Map';
import DirectorImg from './DirectorImg';
import Popup from './Popup';



function Home() {

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalOpen(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => setModalOpen(false);

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />




      {/*    
      <div className="album py-2">

<div className="container">
  <h4>Central Silk Board</h4>
  </div>
  </div> */}
      <div className="sericulturalberhampore">
        <div className="album py-0">
          <div className="container">
            <div className="row ">
              <div className="col-md-12" >

                <center>
                  <div className="sericulturalberhampore">
                    <h2 className='mb-1 mt-3'>CENTRAL SERICULTURAL RESEARCH AND TRAINING INSTITUTE</h2>
                  </div>
                  <p className='mb-1'>BERHAMPORE - 742101  (WEST BENGAL)</p>

                  <h6 style={{ color: "red" }}>CENTRAL SILK BOARD</h6>
                  <h6>Ministry of Textiles, Govt. of India</h6>
                </center>

              </div>

              <div className="d-flex justify-content-between align-items-center">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='marqueecolor'>
        <div className="album py-1">

          <div className="container mt-3">
            <marquee><h6 style={{ color: "white" }}>WELCOME TO CENTRAL SERICULTURAL RESEARCH AND TRAINING INSTITUTE | BERHAMPORE - 742101  (WEST BENGAL) | CENTRAL SILK BOARD | Ministry of Textiles, Govt. of India</h6></marquee>

          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="darkBlue" />

      <div className="album py-0">
        <div className="container">

          <div className="row">
             <div className="col-md-4 mt-3">
              <div class="card border-priamry text-white mb-3" >
                <div class="card-body ">
                  <a href='Directorbenefit' >
                    <div className="btn-grad15  mb-1"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> Direct Benifit Transfer </div></a >


                  <div className="btn-grad15  mb-1">  <Womenhelp /></div>

                  <div className="d-grid gap-3">
                    <a className="btn btn-warning btn-sm me-2 mb-2" href="Minutes" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> Rajbhasha Implementation </a>
                  </div>

                  {/* <a className="btn btn-warning btn-sm mb-2 me-2 " target="_blank" rel="noopener noreferrer" href={Howtoapply}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg> How To Apply</a> */}

                  <div className="d-grid gap-1">
                    <a className="btn btn-warning btn-sm me-2 mb-2" href="Success-story" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg> Success Story</a>
                  </div>



                  {/* <a className="btn-grad15 me-2 mb-2" href="#" target="_blank" rel="noopener noreferrer"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: "white" }} class="bi bi-check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                    </svg> FAQ</a> */}

                  <p className="btn-grad15 me-2 mb-1" target="_blank" rel="noopener noreferrer"> <Directori /> </p>



                  <p className="btn-grad15 me-2 mb-1" > <Soilhealth /> </p>

                  <a className="btn-grad15 me-2 mb-1" href="rti"> RTI</a>

                  <a className="btn-grad15 me-2 mb-1" href="tenders">  Tender</a>
                  <a className="btn-grad15 me-2 mb-1" href="about-us">  About Us</a>


                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3">
              <Slider2 />
              <div className="album py-0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 mt-3">
                    </div>
                    <div className="col-md-8 mt-3">
                      <div className="card text-dark bg-white mb-2">

                        <div style={{ color: "Darkblue" }} className="card-header bg-light mb-1">Notice Board</div>
                        <br />
                        <Notice />
                        <br />
                        <div className="d-flex justify-content-between align-items-center">

                        </div>
                      </div>
                      <div className="card border-success text-center mb-2">
                        <div className="card-header">
                          EVENTS HELD
                        </div>
                        <div className="card-body">

                          <EventHeld />
                        </div>
                      </div>
                      <div className="card border-primary text-center">
                        <div className="card-header blink" style={{ color: "maroon" }}>
                          <b>    LATEST NEWS</b>
                        </div>
                        <div className="card-body">
                          <marquee scrollamount="1" direction="up">
                            <p style={{ color: "green" }} className="card-title mb-2"><b> <Admissionpgds /></b></p>
                            <p style={{ color: "green" }} className="card-text mb-1"><b>  <Postponement /></b></p>

                            <p style={{ color: "green" }} className="card-text"> <b><Pgdsmulberry /> </b></p>
                          </marquee>
                        </div>

                      </div>

                    </div>
                    <div className="col-md-4 mt-3">
                      <div class="card border-primary mb-3">
                        <div class="card-header"><center>	Resham Shilp kotha Episode 1</center></div>
                        <div class="card-body text-primary">
                          <center>
                            <ReactPlayer className="mb-2" width='100%' height='100%' url="https://www.youtube.com/watch?v=_vl-Ps_AA3k" />
                          </center>
                          {/* <center>
<iframe className="iframe-fluid" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fchhoton.mazumder%2Fposts%2Fpfbid02rynFHSM4H6FiBj3zrqchtMX5P4j89pgbTvnxRRT22virHkYg4oHR6yEuSYDWvJx9l&show_text=true&width=500"   scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</center> */}


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>

        </div>
      </div>



      <br />





      <div className='App'>

        <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
          <div className="album py-2">
            <div className="container">
              <div className="row">
                <br />
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="d-flex justify-content-end align-items-center mb-3">
            <button className='buttonclose mt-2' onClick={closeModal}>Close <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg></button>
          </div>

          <Popup />

          <br />




          {/* <div className="album py-2">
        <div className="container">
            <div className="row">

                <Carousel responsive={responsive}>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={muftilogo} alt="blackberrys" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Loiusphilippe} alt="Loiusphilippe" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={blackberrys} alt="muftilogo" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={VanHeusen} alt="blackberrys" />

                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Allensolly} alt="Allensolly" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={Parkavenue} alt="Parkavenue" />

                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={spykar} alt="spykar" />

                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={killer} alt="killer" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={turtle} alt="turtle" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={beinghuman} alt="beinghuman" />
                    </div>

                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={mantecarlo} alt="mantecarlo" />
                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={papejeanslondon} alt="papejeanslondon" />
                    </div>
                    <div className="card mb-3 me-3" >
                        <img className="card-img-top" src={sweetdream} alt="sweetdream" />
                    </div>
                </Carousel>
            </div>
        </div>

    </div> */}

        </Modal>
      </div>


      <div className='events'>
        <br />
        <div className="album py-1">
          <div className="container">
            <div className="col-md-1">
              <h3>News</h3>
              <hr />
            </div>

          </div>
        </div>

      </div>
      <div className='color'>
        <div className="album py-1">
          <div className="container">

            <div className="row">
              <div className="col-md-9 mt-3">

                <News />

              </div>
              <div className="col-md-3 mt-3">

                <DirectorImg />
                {/* <div className="card">
                  <div className='hoverimage1'>
                    <img src={Julamam} className='card-img-top image2 mb-2 me-2' alt="director" />
                    <div className="overlay1">Dr. Jula S. Nair <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg></div>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title mb-2">Dr. Jula S. Nair</h5>
                    <h4 className="card-title mb-3"> DIRECTOR</h4>
                    <a href='director_speech'>
                      <button className='directors'> DIRECTOR SPEECH</button>
                    </a>
                   
                  </div>

                </div> */}
              </div>

            </div>
          </div>
        </div>
        <br />
        <br />
      </div>

      <div className='researchprogram'>
        <br />
        <br />
        <center><h3>Research Programs</h3></center>
        <br />
        <Program />
        <br />
        <br />
      </div>



      {/* 
      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card border-success text-center ">
                <div className="card-header">
                  EVENTS HELD
                </div>
                <div className="card-body">

                 <EventHeld/>

                </div>

              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card border-primary text-center">
                <div className="card-header blink" style={{ color: "maroon" }}>
                  <b>    LATEST NEWS</b>
                </div>
                <div className="card-body">
                <marquee scrollamount="1" direction="up"  >
                  <p style={{ color: "green" }} className="card-title mb-5"><b> <Admissionpgds/></b></p>
                  </marquee>
                  <marquee scrollamount="1" direction="up">
                  <p style={{ color: "green" }} className="card-text mb-5"><b>  <Postponement/></b></p></marquee>
                  <marquee scrollamount="1" direction="up">
                  <p style={{ color: "green" }} className="card-text mb-5"> <b><Pgdsmulberry/> </b></p>
                  </marquee>
                </div>

              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card border-danger text-center">
                <div className="card-header">
                  News Update Text
                </div>
                <div className="card-body">
                  <h5 className="card-title">News will be update soon..........</h5>
                  <h6 className="card-text">Description...</h6>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div> */}








    </div>
  )
}

export default Home
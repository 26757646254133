import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";


import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Minutes67th from './Minutes67th';
import Minutes58th from './Minutes58th';
import Minutes66th from './Minutes66th';
import Minutes65th from './Minutes65th';
import Minutes57th from './Minutes57th';
import Minutes64th from './Minutes64th';
import Minutes63th from './Minutes63th';
import Minutes56th from './Minutes56th';
import Minutes62th from './Minutes62th';
import Minutes55th from './Minutes55th';
import meeting61st from "../images/61th Meeting of Research Council (RC).pdf";
import meeting60th from "../images/60th Meeting of Research Council (RC).pdf";
import meeting59th from "../images/59th RC Minutes (CSR&TI, Berhampore).pdf";
import meeting54th from "../images/54th Meeting Research Advisory Committee minutes (CSRTI-BHP).pdf";
import meeting58th  from "../images/58th RC Minutes (CSR&TI, Berhampore).pdf";
import meeting57thHindi from "../images/57th RC Minutes ((Hindi)_CSR&TI, Berhampore).pdf";
import meeting57thEnglish from "../images/57th RC Minutes (CSR&TI, Berhampore).pdf";
import meeting53thHindi from "../images/53rd RAC Minutes  (Hindi-CSR&TI, Berhampore) ).pdf";
import meeting53thEnglish from "../images/53rd RAC Minutes (CSR&TI, Berhampore).pdf";
import meeting52thHindi from "../images/52nd RAC Minutes  (Hindi-CSR&TI, Berhampore) ).pdf";
import meeting52thEnglish from "../images/APPROVED-52nd-RAC-PROC-CSRTI-BHP-28JAN21.pdf";
import meeting56thHindi from "../images/56 rc hindi version.pdf";
import meeting56thEnglish from "../images/56 RC minutes (final).pdf";
import meeting55th from "../images/55 RC minutes.pdf";
import meeting54th22  from "../images/54th RC Minutes.pdf";
import meeting53th23 from "../images/53 RC Minutes final.pdf";
import meeting51th24 from "../images/51st RAC.pdf";
import meeting52th24 from "../images/52nd RC MINUTES.pdf";
import meeting51th25 from "../images/51st RC Minutes (Final).pdf";
import meeting50th26 from "../images/50th RAC Minutes  (17.7.19).pdf";
import meeting50th27 from  "../images/50th RC Minutes (CSR&TI, Berhampore).pdf";
import meeting49th28 from "../images/49th RAC Minutes.pdf";
import meeting31thDecember from "../images/official language implementation.pdf";
import meeting49th30 from "../images/Minutes 49th (Final).pdf";
import Annualmeeting from "../images/Minutes AAP 2018-19.pdf";
import m48 from "../images/Minutes-48th_RAC.pdf";
import m47 from "../images/Minutes_47_RAC.pdf";
import m47no2 from "../images/47th RC Minutes.pdf";
import m46 from "../images/Minutes_46_RC.pdf";
import m45 from "../images/Minutes_45th_RAC.pdf";
import mextentionM from "../images/Minutes_EOM_17-01-17.pdf";
import m45no from "../images/Minutes_45_RC.pdf";
import m44  from "../images/Minutes_44_RAC.pdf";
import m2 from "../images/Minutes_EOM_19-07-16.pdf";
import m44no2 from "../images/Minutes_44_RC.pdf";

import m43 from "../images/Minutes_43_RAC.pdf";
import Minutes68th from './Minutes68th';
import Minutes69th from './Minutes69th';
import Minutes70th from './Minutes70th';
import Minutes59th from './Minutes59th';






function PubMinutes() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
       <br/> 
       <ScrollToTop smooth top="100" color="maroon"/>    
      <div className="sericultural">

<div className="album py-1">

    <div className="container mt-3">
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Minutes</li>
            </ol>
        </nav>
    </div>
</div>
</div>
<br />
<div className="album py-1">
    <div className="container">
        <Table striped bordered hover variant="light">
      <thead>
        <tr>
          <th><center>Date</center></th>
        
          <th>  <center>Subject </center></th>
         
          <th><center>Version</center>          </th>
      
        </tr>
      </thead>
      <tbody>
      <tr>
          <td>3rd & 11th 02. 2025</td>
          <td>70th Meeting of Research Council (RC)	</td>
          <td>	<Minutes70th/></td>
        
        </tr>
        <tr>
          <td>19.11.2024</td>
          <td>69th Meeting of Research Council (RC)	</td>
          <td><Minutes69th/></td>
        
        </tr>
        <tr>
          <td> 10.06.2024</td>
          <td>68th Meeting of Research Council (RC)	</td>
          <td>	<Minutes68th/></td>
        
        </tr>
        <tr>
          <td>26.03.2024</td>
          <td>67th Meeting of Research Council (RC)	</td>
          <td>	<Minutes67th/></td>
        
        </tr>
        <tr>
          <td>21.10.2024</td>
          <td>59th Meeting of Research Advisory Committee (RAC)	</td>
          <td><Minutes59th/></td>
        </tr>
        <tr>
          <td>28.03.2024</td>
          <td>58th Meeting of Research Advisory Committee (RAC)	</td>
          <td><Minutes58th/></td>
        </tr>
        <tr>
          <td>13.12.2023</td>
          <td>66th Meeting of Research Council (RC)	</td>
          <td><Minutes66th/></td>
        </tr>
     
        <tr>
          <td>23.09.2023</td>
          <td>65th Meeting of Research Council (RC)		</td>
          <td><Minutes65th/></td>
        </tr>
     
        <tr>
          <td>20.07.2023</td>
          <td>57th Meeting of Research Advisory Committee (RAC)	</td>
          <td><Minutes57th/></td>
        </tr>
     
        <tr>
          <td>15.07.2023</td>
          <td>64th Meeting of Research Council (RC)		</td>
          <td><Minutes64th/></td>
        </tr>
     
        <tr>
          <td>14.02.2023</td>
          <td>63rd Meeting of Research Council (RC)		</td>
          <td><Minutes63th/> </td>
        </tr>
        <tr>
          <td>17.01.2023</td>
          <td>  56th Meeting of Research Advisory Committee (RAC)		</td>
          <td><Minutes56th/></td>
        </tr>
        <tr>
          <td>15.12.2022</td>
          <td>62nd Meeting of Research Council (RC)		</td>
          <td><Minutes62th/> </td>
        </tr>
        <tr>
          <td>05.08.2022</td>
          <td> 55th Meeting of Research Advisory Committee (RAC)	</td>
          <td><Minutes55th/> </td>
        </tr>
     
       





        <tr>
          <td>05.07.2022</td>
          <td>	61st Meeting of Research Council (RC)	</td>
          <td><a href={meeting61st}>	English Version</a></td>
        </tr>
        <tr>
          <td>22.04.2022</td>
          <td>60th Meeting of Research Council (RC)	</td>
          <td><a href={meeting60th}>	English Version</a></td>
        </tr>
        <tr>
          <td>19.01.2022</td>
          <td> 59th Meeting of Research Council (RC)	</td>
          <td><a href={meeting59th}>	English Version</a></td>
        </tr>
        <tr>
          <td>22.01.2022</td>
          <td> 	54th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={meeting54th}>	English Version</a></td>
        </tr>
        <tr>
          <td>11.11.2021</td>
          <td> 	58th Meeting of Research Council (RC)	</td>
          <td><a href={meeting58th}>	English Version</a></td>
        </tr>

        <tr>
          <td>23.07.2021</td>
          <td> 57th Meeting of Research Council (RC)	</td>
          <td><a className="me-3" href={meeting57thHindi}>	Hindi Version</a>  <a href={meeting57thEnglish}>	English Version</a> </td>
        </tr>
        <tr>
          <td>23.08.2021</td>
          <td>53rd Meeting of Research Advisory Committee (RAC)	</td>
          <td><a className="me-3" href={meeting53thHindi}>	Hindi Version</a>  <a href={meeting53thEnglish}>	English Version</a> </td>
        </tr>
        <tr>
          <td>28.01.2021</td>
          <td> 	52nd Meeting of Research Advisory Committee (RAC)	</td>
          <td><a className="me-3" href={meeting52thHindi}>	Hindi Version</a>  <a href={meeting52thEnglish}>	English Version</a> </td>
        </tr>
        <tr>
          <td>08.12.2020</td>
          <td> 56th Meeting of Research Council (RC)</td>
          <td><a className="me-3" href={meeting56thHindi}>	Hindi Version</a>  <a href={meeting56thEnglish}>	English Version</a> </td>
        </tr>
        <tr>
          <td>03.09.2020</td>
          <td>	55th Meeting of Research Council (RC)	</td>
          <td><a href={meeting55th}>	English Version</a></td>
        </tr>






        <tr>
          <td>07.07.2020</td>
          <td>		54th Meeting of Research Council (RC)</td>
          <td><a href={meeting54th22}>	English Version</a></td>
        </tr>
        <tr>
          <td>13.03.2020</td>
          <td>	53rd Meeting of Research Council (RC)	</td>
          <td><a href={meeting53th23}>	English Version</a></td>
        </tr>

        <tr>
          <td>22.02.2020</td>
          <td>		51st Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={meeting51th24}>	English Version</a></td>
        </tr>
        <tr>
          <td>06.12.2019</td>
          <td>		52nd Meeting of Research Council (RC)</td>
          <td><a href={meeting52th24}>	English Version</a></td>
        </tr>
        <tr>
          <td>03.09.2019</td>
          <td>		51st Meeting of Research Council (RC)</td>
          <td><a href={meeting51th25}>	English Version</a></td>
        </tr>

        <tr>
          <td>11.07.2019</td>
          <td>	50th Meeting of Research Advisory Committee (RAC)	</td>
          <td><a href={meeting50th26}>	English Version</a></td>
        </tr>
        <tr>
          <td>26.04.2019</td>
          <td>		50th Meeting of Research Council (RC)	</td>
          <td><a href={meeting50th27}>	English Version</a></td>
        </tr>

        <tr>
          <td>15.01.2019</td>
          <td>	49th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={meeting49th28}>	English Version</a></td>
        </tr>
        <tr>
          <td>08.01.2019</td>
          <td>		संस्थान में दिनांक 31 दिसम्बर, 2018 को आयोजित राजभाषा कार्यान्वयन समिति की 117 वीं बैठक की कार्यवृत्त का अग्रेषण !	</td>
          <td><a href={meeting31thDecember}>	English Version</a></td>
        </tr>

        <tr>
          <td>16.11.2018</td>
          <td>49th Meeting of Research Council (RC)</td>
          <td><a href={meeting49th30}>	English Version</a></td>
        </tr>
        <tr>
          <td>23.02.2018</td>
          <td>	Annual Action Plan Meeting</td>
          <td><a href={Annualmeeting}>	English Version</a></td>
        </tr>
     



        <tr>
          <td>24.07.2018</td>
          <td>		48th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={m48}>	English Version</a></td>
        </tr>
        <tr>
          <td>28.06.2018</td>
          <td>	48th Meeting of Research Council (RC)</td>
          <td><a href={m48}>	English Version</a></td>
        </tr>
        <tr>
          <td>09.01.2018</td>
          <td>		47th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={m47}>	English Version</a></td>
        </tr>
        <tr>
          <td>22.11.2017</td>
          <td>	47th Meeting of Research Council (RC)</td>
          <td><a href={m47no2}>	English Version</a></td>
        </tr>

        <tr>
          <td>21.08.2017</td>
          <td>		46th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href="#">	English Version</a></td>
        </tr>
        <tr>
          <td>12.07.2017</td>
          <td>	46th Meeting of Research Council (RC)</td>
          <td><a href={m46}>	English Version</a></td>
        </tr>
        <tr>
          <td>18.01.2017</td>
          <td>	45th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={m45}>	English Version</a></td>
        </tr>
        <tr>
          <td>17.01.2017</td>
          <td>	Minutes of 2nd Extension Officers Meeting (16-17)</td>
          <td><a href={mextentionM}>	English Version</a></td>
        </tr>
        <tr>
          <td>15.11.2016</td>
          <td>	45th Meeting of Research Council (RC)</td>
          <td><a href={m45no}>	English Version</a></td>
        </tr>
        <tr>
          <td>20.07.2016</td>
          <td>		44th Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={m44}>	English Version</a></td>
        </tr>
        <tr>
          <td>19.07.2016</td>
          <td>	Minutes of 1st Extension Officers Meeting (16-17)</td>
          <td><a href={m2}>	English Version</a></td>
        </tr>
        <tr>
          <td>13.04.2016</td>
          <td>		44th Meeting of Research Council (RC)</td>
          <td><a href={m44no2}>	English Version</a></td>
        </tr>
        <tr>
          <td>16.03.2016</td>
          <td>		43rd Meeting of Research Advisory Committee (RAC)</td>
          <td><a href={m43}>	English Version</a></td>
        </tr>
  
      </tbody>
    </Table>
    </div>

    </div>

        <br/>


        <br/>


    </div>
  )
}

export default PubMinutes